import React, { useState, useEffect } from 'react';
import { ArrowLeft, Globe, Copy, Eraser, BarChart, Clock } from 'lucide-react';
import { FaLanguage, FaExchangeAlt } from "react-icons/fa";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from "../firebase";
import { useNavigate } from 'react-router-dom';
import Footer from './footer';

const Models = ({ language }) => {
    const [selectedModel, setSelectedModel] = useState(null);
    const navigate = useNavigate();
    const [publicSotaModels, setPublicSotaModels] = useState([]);

    const translations = { 
        publicAgents: language === "gaia-en" ? "Public Agents" : "Agentes Públicos",
        loading: language === "gaia-en" ? "Loading..." : "Cargando...",
    };

    const handleSelectModel = (model) => {
        navigate(`/models/${model.id}`);
    };

    useEffect(() => {
        const fetchPublicSotaModels = async () => {
            try {
                const sotaRef = collection(db, "sota");
                const q = query(sotaRef, where("public", "==", true));
                const querySnapshot = await getDocs(q);
                const modelsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPublicSotaModels(modelsList);
            } catch (error) {
            }
        };

        fetchPublicSotaModels();
    }, []);

    const handleGoBack = () => {
        setSelectedModel(null);
    };

    const formatDate = (timestamp) => {
        if (!timestamp || !timestamp.toDate) return 'N/A';
        const date = timestamp.toDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    };

    if (selectedModel) {
        return <TranslationInterface model={selectedModel} onGoBack={handleGoBack} language={language} />;
    }

    return (
        <div className='min-h-screen bg-gradient-to-r from-gray-950 via-gray-950 to-black flex flex-col'>
            <div className="flex-grow container mx-auto px-4 sm:px-20 py-8">
                <h2 className="text-2xl text-white font-bold mb-6">{translations.publicAgents}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {publicSotaModels.map(model => (
                        <div
                            key={model.id}
                            className="bg-gray-800 hover:bg-gray-700 cursor-pointer px-4 py-6 rounded-lg text-white"
                            onClick={() => handleSelectModel(model)}
                        >
                            <div className="grid grid-cols-2 gap-2 text-base">
                                <div className="flex items-center">
                                    <Globe size={16} className="mr-2 text-blue-500" />
                                    {model.src_lang || 'N/A'} → {model.tgt_lang || 'N/A'}
                                </div>
                                {model.custom_prompt && (
                                    <div className="flex items-center col-span-2">
                                        <BarChart size={16} className="mr-2 text-blue-500" />
                                        {model.custom_prompt}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                {publicSotaModels.length === 0 && (
                    <div className="flex justify-center items-center py-10">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Models;

const TranslationInterface = ({ model, language, onGoBack }) => {
    const [sourceText, setSourceText] = useState('');
    const [translation, setTranslation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [srcLang, setSrcLang] = useState(model?.src_lang);
    const [tgtLang, setTgtLang] = useState(model?.tgt_lang);


    const translations = {
        backToModels: language === "gaia-en" ? "Back to Models" : "Volver a Modelos",
        enterTextToTranslate: language === "gaia-en" ? "Enter text to translate" : "Ingrese texto para traducir",
        translating: language === "gaia-en" ? "Translating..." : "Traduciendo...",
        translate: language === "gaia-en" ? "Translate" : "Traducir",
        translationOptions: language === "gaia-en" ? "Translation Options" : "Opciones de Traducción",
        explanation: language === "gaia-en" ? "Explanation" : "Explicación",
        invalidResponse: language === "gaia-en" ? "Invalid response from server" : "Respuesta inválida del servidor",
        translateFailed: language === "gaia-en" ? "Translation request failed" : "Error en la solicitud de traducción"
    };

    const handleTranslate = async () => {
        if (!sourceText.trim()) return;
        setLoading(true);
        setTranslation(null);

        try {
            const response = await fetch(
                `https://fst.gaia-ml.com/api/translate/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        text: sourceText,
                        model_id: model.id,
                        src_lang: srcLang,
                        tgt_lang: tgtLang,
                        index_name: model.index_name,
                        file_path: model.file_path,
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || translations.translateFailed);
            }

            const data = await response.json();
            if (data.success && data.data) {
                setTranslation(data.data);
            } else {
                setTranslation({ error: translations.invalidResponse });
            }
        } catch (error) {
            setTranslation({ error: error.message });
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleLanguageSwap = () => {
        setSrcLang(tgtLang);
        setTgtLang(srcLang);
        setSourceText('');
        setTranslation(null);
    };

    return (
        <div translate="no" className="min-h-screen bg-gradient-to-r from-gray-950 via-gray-950 to-black text-white">
            <div className="container mx-auto px-4 sm:px-20 py-8">
                <div className="flex justify-between items-center mb-6">
                    <button
                        onClick={onGoBack}
                        className="flex items-center text-blue-500 hover:text-blue-600"
                    >
                        <ArrowLeft size={20} className="mr-2" />
                        {translations.backToModels}
                    </button>
                </div>
                <h2 className="text-xl text-blue-500 font-semibold mb-6 flex items-center">
                    <Globe className="mr-2 text-blue-500" size={24} />
                    {srcLang} → {tgtLang}
                </h2>

                <div className="flex flex-wrap items-center gap-8 mb-6">
                    <div className="flex-1">
                        <div className="w-full rounded-xl border border-gray-700 p-3 bg-gray-800">
                            {srcLang}
                        </div>
                    </div>

                    <div className="flex items-center">
                        <button
                            type="button"
                            onClick={handleLanguageSwap}
                            className="p-3 rounded-full hover:bg-gray-700 text-gray-300"
                        >
                            <FaExchangeAlt />
                        </button>
                    </div>

                    <div className="flex-1">
                        <div className="w-full rounded-xl border border-gray-700 p-3 bg-gray-800">
                            {tgtLang}
                        </div>
                    </div>
                </div>

                <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                        <label className="text-base font-medium text-gray-300">
                            {srcLang}
                        </label>
                        <div className="flex gap-2">
                            <button
                                onClick={() => {
                                    setSourceText('');
                                    setTranslation(null);
                                }}
                                className="text-gray-400 hover:text-blue-500"
                            >
                                <Eraser size={18} />
                            </button>
                        </div>
                    </div>
                    <textarea
                        className="w-full p-2 border outline-none border-gray-700 rounded-md h-40 resize-none bg-gray-800 text-white"
                        value={sourceText}
                        onChange={(e) => setSourceText(e.target.value)}
                        placeholder={translations.enterTextToTranslate}
                    />
                </div>

                <div className="flex justify-center mb-6">
                    <button
                        className={`rounded-full bg-blue-500 py-3 px-8 text-white font-semibold shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 transition ${loading || !sourceText ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={handleTranslate}
                        disabled={loading || !sourceText.trim()}
                    >
                        {loading ? (
                            <div className="flex items-center">
                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                                {translations.translating}
                            </div>
                        ) : (
                            <div className="flex items-center">
                                <FaExchangeAlt className="mr-2" />
                                {translations.translate}
                            </div>
                        )}
                    </button>
                </div>

                {translation && (
                    <div className="mt-6 space-y-8">
                        {translation.error && (
                            <div className="p-4 bg-red-500 bg-opacity-25 border border-red-500 rounded-md text-red-300">
                                {translation.error}
                            </div>
                        )}

                        {translation.translations && (
                            <div className="bg-gray-800 shadow-md rounded-2xl p-6">
                                <h3 className="text-xl font-semibold mb-5 text-blue-500 flex items-center">
                                    <FaLanguage className="mr-2" /> {translations.translationOptions}
                                </h3>
                                <ul className="space-y-4">
                                    {translation.translations.map((t, i) => (
                                        <li
                                            key={i}
                                            className="p-4 bg-gray-900 rounded-lg border border-gray-700 text-gray-300 shadow-sm flex justify-between items-center"
                                        >
                                            <span>{t}</span>
                                            <button onClick={() => handleCopy(t)} className="text-gray-400 hover:text-blue-500">
                                                <Copy size={18} />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
