import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FaLanguage, FaExchangeAlt, FaTrash, FaInternetExplorer } from "react-icons/fa";
import { ChevronLeft } from 'lucide-react';

const ModelDetail = ({ user, language }) => {
    const { modelId } = useParams();
    const [model, setModel] = useState(null);
    const [error, setError] = useState(null); 
    const [text, setText] = useState("");
    const [coreMemory, setCoreMemory] = useState("")
    const [srcLang, setSrcLang] = useState("");
    const [tgtLang, setTgtLang] = useState("");
    const [translation, setTranslation] = useState(null);
    const [details, setDetails] = useState(null)
    const [loading, setLoading] = useState(false);
    const [visibilityLoading, setVisibilityLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchModel = async () => {
            try {
                const modelDoc = await getDoc(doc(db, 'sota', modelId));
                if (modelDoc.exists()) {
                    const modelData = { id: modelDoc.id, ...modelDoc.data() };
                    setModel(modelData);
                    setSrcLang(modelData.src_lang);
                    setTgtLang(modelData.tgt_lang);
                    setCoreMemory(modelData.core_memory)
                } else {
                    setError('Model not found.');
                }
            } catch (err) {
                setError('Failed to fetch model details.');
            }
        }; 

        fetchModel();
    }, [modelId]);

    const updateModelVisibility = async (modelId, newPublicValue) => {
        setVisibilityLoading(true);
        try {
            const modelRef = doc(db, "sota", modelId);
            await updateDoc(modelRef, {
                public: newPublicValue
            });
            setModel({ ...model, public: newPublicValue });
        } catch (error) {
        } finally {
            setVisibilityLoading(false);
        }
    };

    const handleLanguageSwap = () => {
        setSrcLang(tgtLang);
        setTgtLang(srcLang);
        setText("");
        setTranslation(null);
        setDetails(null)
    };

    const handleTranslate = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setTranslation(null);
        setDetails(null)

        try {
            const response = await fetch(
                `https://agentic.gaia-ml.com/api/translate/`,
                { 
                    method: 'POST', 
                    headers: { 
                        'Content-Type': 'application/json',
                        'X-API-Key': '1kfb_wSn68Gzd-V4n18t3SqaZnis3bb-TqE8YazruII'
                    }, 
                    body: JSON.stringify({
                        text: text,
                        model_id: modelId,
                        src_lang: srcLang,
                        tgt_lang: tgtLang
                    }),
                }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Translation request failed");
            }

            const data = await response.json();
            if (data.success && data.data) {
                setTranslation(data.data); 
                setDetails(data)
            } else {
                setError("Invalid response format from server");
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteModel = async () => {
        if (!window.confirm('Are you sure you want to delete this model? This action cannot be undone.')) {
            return;
        }

        setDeleteLoading(true);
        setError(null);

        try {
            const deleteIndexResponse = await fetch(
                `https://agentic.gaia-ml.com/api/delete-model/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        model_id: modelId
                    }),
                }
            );

            if (!deleteIndexResponse.ok) {
                const errorData = await deleteIndexResponse.json();
                throw new Error(errorData.error || "Failed to delete model");
            }
            navigate('/dashboard');
        } catch (error) {
            setDeleteLoading(false);
            throw error;
        }
    };

    if (!model) {
        return (
            <div translate="no" className="flex justify-center py-12">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </div>
        );
    }

    const toggleStyles = `
        .toggle-checkbox:checked {
            right: 0;
            border-color: #68D391;
        }
        .toggle-checkbox:checked + .toggle-label {
            background-color: #68D391;
        }
    `; 

    return (
        <div className="min-h-screen mx-auto px-4 pb-8 pt-24">
            <div className="max-w-full sm:px-12 mx-auto">
                <div className="flex flex-col lg:flex-row gap-8">
                    <div className="lg:w-1/2">
                        <div className="mb-6">
                            <button
                                className="text-indigo-600 hover:text-indigo-800 flex items-center"
                                onClick={() => navigate(-1)}
                            >
                                <ChevronLeft size={20} className="mr-1" />
                                {language === "gaia-en" ? "Back to Dashboard" : "Volver al Panel"}
                            </button>
                        </div>

                        <h2 className="text-2xl font-semibold text-indigo-700 mb-6">
                            {srcLang} → {tgtLang}
                        </h2>

                        <form onSubmit={handleTranslate} className="space-y-8">
                            <div className="relative grid grid-cols-1 md:grid-cols-2 gap-12">
                                <div className="flex flex-col justify-center items-center">
                                    <label className="block text-base font-medium text-indigo-700 mb-3 flex items-center">
                                        <FaLanguage className="mr-2" />
                                        {language === "gaia-en" ? "Source Language" : "Idioma de Origen"}
                                    </label>
                                    <div className="w-5/6 rounded-xl border border-indigo-200 p-3 bg-white">
                                        {srcLang}
                                    </div>
                                </div>

                                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                                    <button
                                        type="button"
                                        onClick={handleLanguageSwap}
                                        className="p-3 rounded-full hover:bg-indigo-100 text-indigo-600 bg-white shadow-md"
                                    >
                                        <FaExchangeAlt />
                                    </button>
                                </div>

                                <div className="flex flex-col justify-center items-center">
                                    <label className="block text-base font-medium text-indigo-700 mb-3 flex items-center">
                                        <FaLanguage className="mr-2" />
                                        {language === "gaia-en" ? "Target Language" : "Idioma Objetivo"}
                                    </label>
                                    <div className="w-5/6 rounded-xl border border-indigo-200 p-3 bg-white">
                                        {tgtLang}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="block text-base font-medium text-indigo-700 mb-3 flex items-center">
                                    <FaLanguage className="mr-2" />
                                    {language === "gaia-en" ? "Text to Translate" : "Texto a Traducir"}
                                </label>
                                <textarea
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    rows={4}
                                    className="w-full rounded-xl border border-indigo-200 p-4 outline-none"
                                    placeholder={language === "gaia-en" ? "Enter text to translate..." : "Ingrese texto para traducir..."}
                                />
                            </div>

                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    disabled={loading || !text}
                                    className="rounded-full bg-gradient-to-r from-indigo-600 to-blue-600 py-3 px-8 text-white font-semibold shadow-lg hover:from-indigo-500 hover:to-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 transition disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    {loading ? (language === "gaia-en" ? "Translating..." : "Traduciendo...") : (
                                        <div className="flex items-center">
                                            <FaExchangeAlt className="mr-2" />
                                            {language === "gaia-en" ? "Translate" : "Traducir"}
                                        </div>
                                    )}
                                </button>
                            </div>
                        </form>

                        {/* Translation results */}
                        {error && (
                            <div className="mt-8 p-5 bg-red-100 border border-red-300 rounded-xl text-red-700 shadow-sm">
                                <p>{error}</p>
                            </div>
                        )}

                        {translation && (
                            <>
                                <p className="py-8 text-gray-500">
                                    {language === "gaia-en" ? "Translation time:" : "Tiempo de traducción:"} {details.response_time ? `${details.response_time} ${language === "gaia-en" ? "seconds" : "segundos"}` : language === "gaia-en" ? "Not available" : "No disponible"}
                                </p>
                                <div className="space-y-8">
                                    <div className="bg-white shadow-md rounded-2xl p-6">
                                        <h3 className="text-xl font-semibold mb-5 text-indigo-600 flex items-center">
                                            <FaLanguage className="mr-2" />
                                            {language === "gaia-en" ? "Translation Options" : "Opciones de Traducción"}
                                        </h3>
                                        <ul className="space-y-4">
                                            {translation.translations ? (
                                                translation.translations.map((t, i) => (
                                                    <li
                                                        key={i}
                                                        className="p-4 bg-gray-50 rounded-lg border border-gray-200 text-gray-800 shadow-sm"
                                                    >
                                                        {t}
                                                    </li>
                                                ))
                                            ) : translation.raw_translation ? (
                                                (() => {
                                                    const translations = translation.raw_translation
                                                        .match(/\d+\.\s*(.*?)(?=(?:\n\d+\.|$))/gs)
                                                        ?.map(t => t.replace(/^\d+\.\s*/, '').trim())
                                                        || [];

                                                    return translations.map((t, i) => (
                                                        <li
                                                            key={i}
                                                            className="p-4 bg-gray-50 rounded-lg border border-gray-200 text-gray-800 shadow-sm"
                                                        >
                                                            {t}
                                                        </li>
                                                    ));
                                                })()
                                            ) : (
                                                <li className="p-4 bg-gray-50 rounded-lg border border-gray-200 text-gray-800 shadow-sm">
                                                    {language === "gaia-en" ? "No translations available" : "No hay traducciones disponibles"}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    {/* Right half - API Documentation */}
                    <div className="lg:w-1/2 lg:border-l lg:pl-8">
                        <div className="sticky top-24">
                            <div className="space-y-6">
                                <div className="bg-white p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 flex items-center">
                                        {language === "gaia-en" ? "API Documentation" : "Documentación API"}
                                    </h3>

                                    <div className="space-y-6">
                                        <div>
                                            <h4 className="text-lg font-medium mb-2">
                                                {language === "gaia-en" ? "Translation Endpoint" : "Endpoint de Traducción"}
                                            </h4>
                                            <pre className="text-sm bg-zinc-800 text-white p-4 rounded block overflow-x-auto font-mono">
                                                {`curl -X POST https://agentic.gaia-ml.com/api/translate/ \\
    -H "Content-Type: application/json" \\
    -H "X-API-Key: your_api_key_here" \\
    -d '{
        "text": "Hola a todos",
        "src_lang": "${srcLang}", 
        "tgt_lang": "${tgtLang}",
        "model_id": "${model?.id}"
    }'`}
                                            </pre>
                                        </div>

                                        <div>
                                            <div className="flex items-center space-x-4">
                                                <span className="font-medium">
                                                    {language === "gaia-en" ? "Visibility:" : "Visibilidad:"}
                                                </span>
                                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                    <input
                                                        type="checkbox"
                                                        name="toggle"
                                                        id="toggle"
                                                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                                        checked={model.public}
                                                        disabled={visibilityLoading}
                                                        onChange={() => {
                                                            const newPublicValue = !model.public;
                                                            updateModelVisibility(model.id, newPublicValue);
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor="toggle"
                                                        className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                                    ></label>
                                                </div>
                                                <span className="text-base font-medium">
                                                    {model.public ?
                                                        <p className="text-green-500">True</p> :
                                                        <p className="text-red-500">False</p>
                                                    }
                                                </span>

                                                <button
                                                    onClick={handleDeleteModel}
                                                    disabled={deleteLoading}
                                                    className="flex items-center px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-lg transition disabled:opacity-50 disabled:cursor-not-allowed ml-auto"
                                                >
                                                    <FaTrash className="mr-2" />
                                                    {deleteLoading
                                                        ? (language === "gaia-en" ? "Deleting..." : "Eliminando...")
                                                        : (language === "gaia-en" ? "Delete Model" : "Eliminar Modelo")}
                                                </button>
                                            </div>
                                        </div>

                                        <style>{`
                                            .toggle-checkbox:checked {
                                                right: 0;
                                                border-color: #68D391;
                                            }
                                            .toggle-checkbox:checked + .toggle-label {
                                                background-color: #68D391;
                                            }
                                        `}</style>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModelDetail;
